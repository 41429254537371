import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Popover, Row, Select, Typography } from 'antd';
import styled from 'styled-components';
import UserInfoTable from '../components/UserInfoTable';
import {
  getTradePageUrl,
  MarketProvider,
  useSymbol,
} from '../utils/markets';
import TradeForm from '../components/TradeForm';
import { useHistory, useParams } from 'react-router-dom';
import AdvancedChart from '../components/TradingView/AdvancedChart';
import MarketOverview from '../components/TradingView/MarketOverview';

// import { TVChartContainer } from '../components/TradingView';

// const { Option, OptGroup } = Select;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`;

export default function StockPage() {
  const { marketAddress } = useParams();
  useEffect(() => {
    if (marketAddress) {
      console.log('market address is')
      console.log(marketAddress)

      localStorage.setItem('marketAddress', JSON.stringify(marketAddress));
    }
  }, [marketAddress]);
  const history = useHistory();
  function setMarketAddress(address) {
    history.push(getTradePageUrl(address));
  }

  return (
    <MarketProvider
      marketAddress={marketAddress}
      setMarketAddress={setMarketAddress}
    >
      <StockPageInner />
    </MarketProvider>
  );
}

function StockPageInner() {
  const marketName = "Stock";
  const { setSymbol } = useSymbol();
  useEffect(() => {
    document.title = marketName ? `${marketName} — Yan` : 'Yan Trade';
  }, [marketName]);

  const changeOrderRef = useRef<
    ({ size, price }: { size?: number; price?: number }) => void
  >();

  // const width = dimensions?.width;
  const componentProps = {
    onChangeOrderRef: (ref) => (changeOrderRef.current = ref),
    onPrice: useCallback(
      (price) => changeOrderRef.current && changeOrderRef.current({ price }),
      [],
    ),
  };
  const component = (() => {
    return <RenderNormal {...componentProps} />
  })();

  return (
    <>
      <Wrapper>
        {component}
      </Wrapper>
    </>
  );
}

const RenderNormal = ({ onChangeOrderRef, onPrice }) => {
  return (
    <Row
      style={{
        minHeight: '900px',
        flexWrap: 'nowrap',
      }}
    >
      <Col
        flex="400px"
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <MarketOverview />
        <TradeForm setChangeOrderRef={onChangeOrderRef} />
      </Col>
      <Col flex="auto" style={{ height: '100%' }}>
        {/* <TVChartContainer /> */}
        <AdvancedChart widgetProps={{ theme: "dark" }} symbol={"NASDAQ:TSLA"} height={"900px"} advanced={true} />
        <UserInfoTable />
      </Col>
    </Row>
  );
};
