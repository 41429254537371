import React from "react";
import { useMarket, useSymbol } from "../../utils/markets";

declare const TradingView: any;

// https://github.com/k-128/react-tradingview-embed/tree/master/react-tradingview-embed/src/components

export type AdvancedChartWidgetProps = {
  width?: string | number;
  height?: string | number;
  autosize?: boolean;
  symbol?: string;
  interval?: string;
  range?: string;
  timezone?: string;
  theme?: string;
  style?: string;
  locale?: string;
  toolbar_bg?: string;
  hide_top_toolbar?: boolean;
  hide_side_toolbar?: boolean;
  withdateranges?: boolean;
  save_image?: boolean;
  enable_publishing?: boolean;
  allow_symbol_change?: boolean;
  container_id?: string;
}

type AdvancedChartProps = {
  symbol: string;
  widgetProps?: AdvancedChartWidgetProps;
  widgetPropsAny?: any;
  children?: never;
  height?:string;
  advanced?: boolean;
};

const symbolMapping = (name?: string) => {
  switch(name) {
    case "CEL/USDT": {
       return "BINGX:CELUSDT"
    }
    case "LUA/USDT": {
      return "FTX:LUAUSDT"
    }
    case "OXY/USDT": {
      return "FTX:OXYUSDT"
    }
    case "ETH/USDT": {
      return "BINANCE:ETHUSDT"
    }
    case "ETH/USDC": {
      return "BINANCEUS:ETHUSDC"
    }
    case "AKRO/USDT": {
      return "BINANCE:AKROUSDT"
    }
    case "ALEPH/USDT": {
      return "KUCOIN:ALEPHUSDT"
    }
    case "SOL/USDT": {
      return "BINANCE:SOLUSDT"
    }
    default: { 
      return ""
    } 
 }
}

const symbolParse = (name?: string, defaultMarket?: string) => {
  if (name) {
    var symbol = symbolMapping(name);
    if(symbol == ""){
      var tick =name.replace('/','');
      symbol = "BINANCE:" + tick;
    }
    return symbol;
  }

  let market = symbolMapping(name);
  if (market === "" && defaultMarket != undefined) {
    market = defaultMarket;
  }
  return market;
}

const AdvancedChart = (props: AdvancedChartProps) => {
  const { widgetProps, widgetPropsAny } = props;
  const { marketName } = useMarket();
  const { symbol } = useSymbol();

  console.log(marketName);
  console.log(symbol);
  let selectedMarket = marketName;
  if (marketName == undefined && symbol!= ""){
    selectedMarket = symbol; 
  }
  let chartSymbol = symbolParse(selectedMarket, props.symbol);

  if (props.symbol =="NASDAQ:TSLA") {
    chartSymbol = "NASDAQ:TSLA";
  }

  let advanceChart = false;
  if (props.advanced) {
    advanceChart = props.advanced;
  }

  let chartHeight = "640px";
  if (props.height) {
    chartHeight = props.height;
  }

  let containerId = "advanced-chart-widget-container";
  if (widgetProps?.container_id) {
    containerId = widgetProps?.container_id;
  }

  const ref: {current: HTMLDivElement | null} = React.createRef();

  React.useEffect(() => {
    let refValue: any;

    if (ref.current) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/tv.js";
      script.async = true;
      script.onload = () => {
        if (typeof TradingView !== "undefined") {
          new TradingView.widget(
            {
              "width": "100%",
              "height": chartHeight,
              "symbol": chartSymbol,
              "interval": "240",
              "range": "1M",
              "timezone": "Etc/UTC",
              "theme": "dark",
              "style": "1",
              "allow_symbol_change": true,
              "locale": "en",
              "toolbar_bg": "rgba(0, 0, 0, 0.8)",
              "hide_top_toolbar": false,
              "hide_side_toolbar": false,
              "withdateranges": true,
              "save_image": true,
              "enable_publishing": false,
              "details": advanceChart,
              "hotlist": advanceChart,
              "calendar": advanceChart,
              "container_id": containerId,
              ...widgetProps,
              ...widgetPropsAny,
            }
          );
        }
      }
      ref.current.appendChild(script);
      refValue = ref.current;
    }

    return () => {
      if (refValue) {
        while (refValue.firstChild) {
          refValue.removeChild(refValue.firstChild);
        }
      }
    }
  }, [ref, widgetProps, widgetPropsAny, containerId]);

  return <div id={containerId} ref={ref} />;
}

export default AdvancedChart;
