import React from "react";


export type MarketOverviewWidgetProps = {
    colorTheme?: string;
    dateRange?: string;
    showChart?: boolean;
    locale?: string;
    largeChartUrl?: string;
    isTransparent?: boolean;
    showSymbolLogo?: boolean;
    width?: string | number;
    height?: string | number;
    plotLineColorGrowing?: string;
    plotLineColorFalling?: string;
    gridLineColor?: string;
    scaleFontColor?: string;
    belowLineFillColorGrowing?: string;
    belowLineFillColorFalling?: string;
    symbolActiveColor?: string;
    tabs?: any;
}

type MarketOverviewProps = {
  widgetProps?: MarketOverviewWidgetProps;
  widgetPropsAny?: any;
  children?: never;
};

const MarketOverview = (props: MarketOverviewProps) => {
  const { widgetProps, widgetPropsAny } = props;

  const ref: {current: HTMLDivElement | null} = React.createRef();

  React.useEffect(() => {
    let refValue: any;

    if (ref.current) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/"
                    + "embed-widget-market-overview.js";

      script.async = true;
      script.type = "text/javascript";
      script.innerHTML = JSON.stringify({
        "colorTheme": "dark",
        "dateRange": "12M",
        "showChart": false,
        "locale": "en",
        "largeChartUrl": "http://yantrade.pages.dev/stock#/stock",
        "isTransparent": false,
        "showSymbolLogo": true,
        "width": "400",
        "height": "900",
        "plotLineColorGrowing": "rgba(25, 118, 210, 1)",
        "plotLineColorFalling": "rgba(25, 118, 210, 1)",
        "gridLineColor": "rgba(42, 46, 57, 1)",
        "scaleFontColor": "rgba(120, 123, 134, 1)",
        "belowLineFillColorGrowing": "rgba(33, 150, 243, 0.12)",
        "belowLineFillColorFalling": "rgba(33, 150, 243, 0.12)",
        "symbolActiveColor": "rgba(33, 150, 243, 0.12)",
        "tabs": [
            {
                "title": "Stocks",
                "symbols": [
                    {
                    "s": "NASDAQ:AAPL",
                    "d": "AAPL"
                    },
                    {
                    "s": "NASDAQ:TSLA"
                    },
                    {
                    "s": "NASDAQ:AMZN"
                    },
                    {
                    "s": "NASDAQ:META"
                    },
                    {
                    "s": "NASDAQ:NVDA"
                    },
                    {
                    "s": "NASDAQ:MSFT"
                    },
                    {
                    "s": "NASDAQ:NFLX"
                    },
                    {
                    "s": "NASDAQ:GOOGL"
                    },
                    {
                    "s": "NYSE:AMC"
                    }
                ]
            },
            {
              "title": "Indices",
              "symbols": [
                {
                  "s": "FOREXCOM:SPXUSD",
                  "d": "S&P 500"
                },
                {
                  "s": "FOREXCOM:NSXUSD",
                  "d": "US 100"
                },
                {
                  "s": "FOREXCOM:DJI",
                  "d": "Dow 30"
                },
                {
                  "s": "INDEX:NKY",
                  "d": "Nikkei 225"
                },
                {
                  "s": "INDEX:DEU40",
                  "d": "DAX Index"
                },
                {
                  "s": "FOREXCOM:UKXGBP",
                  "d": "UK 100"
                }
              ],
              "originalTitle": "Indices"
            },
            {
              "title": "Bonds",
              "symbols": [
                {
                  "s": "CME:GE1!",
                  "d": "Eurodollar"
                },
                {
                  "s": "CBOT:ZB1!",
                  "d": "T-Bond"
                },
                {
                  "s": "CBOT:UB1!",
                  "d": "Ultra T-Bond"
                },
                {
                  "s": "EUREX:FGBL1!",
                  "d": "Euro Bund"
                },
                {
                  "s": "EUREX:FBTP1!",
                  "d": "Euro BTP"
                },
                {
                  "s": "EUREX:FGBM1!",
                  "d": "Euro BOBL"
                }
              ],
              "originalTitle": "Bonds"
            },
          ],
        ...widgetProps,
        ...widgetPropsAny,
      });

      ref.current.appendChild(script);
      refValue = ref.current;
    }

    return () => {
      if (refValue) {
        while (refValue.firstChild) {
          refValue.removeChild(refValue.firstChild);
        }
      }
    }
  }, [ref, widgetProps, widgetPropsAny]);

  return <div ref={ref} />;
}

export default MarketOverview;
